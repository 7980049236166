import React from "react"
import Lymph from "../components/imagesComponent/services/personal-lymph"
import InHome from "../components/imagesComponent/services/in-home"
import Events from "../components/imagesComponent/services/events"

export default function Services({ item, serviceType = "" }) {
  const { service, options } = item
  console.log({ item })
  return (
    <>
      <div className="bg-primary overflow-hidden shadow-xl w-full rounded-lg h-64">
        {service.category.singleLine === "lymph" && <Lymph />}
        {service.category.singleLine === "in-home" && <InHome />}
        {service.category.singleLine === "chair" && <Events />}
      </div>
      <div>
        <h2 className="text-5xl text-primary lg:mt-0 mt-5 font-serif">
          {service.name}
        </h2>
        <p className="leading-loose mb-4">{service.description}</p>
        <div className="flex flex-wrap">
          {options.map(({ timeAndPrice }, index) => {
            return timeAndPrice.trim() === "" ? null : (
              <a
                key={index}
                className="py-3 px-5 text-orange-900 bg-orange-200 rounded-full hover:bg-orange-300 whitespace-no-wrap mr-3 mb-3"
                href={`https://api.whatsapp.com/send?phone=+17863269958&text=Service Type: ${serviceType} | Service: ${service.name} | Option: ${timeAndPrice}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {timeAndPrice}
              </a>
            )
          })}
        </div>
      </div>
    </>
  )
}
